
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        modalVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        dataSource: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    emits: ['close-modal', 'select-checking-round'],
    setup(props, { emit }) {
        // const dataSource = [
        //     {
        //         checkingRound: '2021 May',
        //         batchCount: '3',
        //     },
        //     {
        //         checkingRound: '2021 Mar',
        //         batchCount: '12',
        //     },
        //     {
        //         checkingRound: '2021 Dec',
        //         batchCount: '12',
        //     },
        // ];
        const columns = [
            {
                title: 'Checking Round',
                dataIndex: 'checkingRound',
            },
            {
                title: 'Batch Count',
                dataIndex: 'batchCount',
            },
            {
                title: 'Operation',
                dataIndex: 'operation',
                slots: { customRender: 'operation' },
            },
        ];

        const closeModal = () => {
            emit('close-modal');
        };

        const select = (record: any) => {
            emit('select-checking-round', record);
            closeModal();
        };

        return { columns, closeModal, select };
    },
});
