
import {
    ref,
    defineComponent,
    reactive,
    computed,
    toRefs,
    onMounted,
    watch,
} from 'vue';
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import Batch from '@/views/Checking/components/Batch.vue';
import CheckingRoundModal from '@/views/Checking/components/CheckingRoundModal.vue';
import SIPOCheckModal from '@/views/Checking/components/SIPOCheckModal.vue';
import SIPOApi from '@/API/checking/checkingSIPO';
import { getUser } from '@/API/checking/checkingManagement';
import fixTable from '@/hooks/checking/useFixTable';
import downloadFile from '@/utils/claim/downloadFile';

interface BatchDataInf {
    batchId: string;
    batchName: string;
    submissionDate: string;
    plan: number;
    additionalNum: number;
    roundId: string;
}

interface CheckingRoundInf {
    checkingRound: string;
    id: string;
    batchCount: number;
    planDetailIds: string;
}

export default defineComponent({
    components: { Batch, CheckingRoundModal, SIPOCheckModal },
    setup() {
        const sipoModalDataSource = ref([]);
        const router = useRouter();
        const pagination = reactive({
            total: 0,
            currentPage: 1,
            pageSize: 20,
        });
        const assignee = ref<string>('');
        const buList = ref<any[]>([]);
        const bus = computed(() => {
            return buList.value
                .map((bu) => {
                    return bu.nameCn;
                })
                .join(',');
        });
        const currentPlanDetailIds = ref<string>('');
        const currentRoundId = ref<string>('');
        const batchName = ref<string>('');
        const submissionDate = ref<string>('');
        const checkingRound = ref<string>('');
        const checkingRoundList = ref<CheckingRoundInf[]>([]);
        const checkingRoundVisible = ref<boolean>(false);
        const programName = ref<string>('');
        const programCode = ref<string>('');
        const sipoReview = ref<number>(3);
        const waitingSipoCommunication = ref<number>(3);
        const sipoSelectItems = ref<any[]>([
            { value: 1, title: '=0' },
            { value: 2, title: '>0' },
            { value: 3, title: 'All' },
        ]);
        const sipoCheckVisible = ref<boolean>(false);
        const sipoCheckTitle = ref<string>('');
        const batchData = ref<BatchDataInf[]>([]);
        const columns = [
            {
                title: 'Program Code',
                dataIndex: 'programCode',
                width: 150,
            },
            {
                title: 'Program Name',
                dataIndex: 'programNameEn',
                // width: 350,
            },
            {
                title: 'Program Category',
                dataIndex: 'programCategoryNameEn',
                width: 200,
            },
            {
                title: 'Eligible Period',
                width: 200,
                slots: { customRender: 'period' },
            },
            {
                title: 'Dealer Qty',
                dataIndex: 'dealerQty',
                width: 150,
            },
            { title: 'SICCO Checking', dataIndex: 'siccoChecking', width: 150 },
            { title: 'SIPO Review', dataIndex: 'sipoReview', width: 150 },
            {
                title: 'Spot Checking',
                dataIndex: 'spotChecking',
                width: 150,
            },
            {
                title: 'Waiting SIPO Communication',
                dataIndex: 'waitingSipoCommunication',
                width: 210,
            },
            {
                title: 'Dealer Communication',
                dataIndex: 'dealerCommunication',
                width: 200,
            },
            {
                title: 'Operation',
                dataIndex: 'operation',
                width: 100,
                fixed: 'right',
                slots: { customRender: 'operation' },
            },
        ];
        const tableData = ref([]);
        const height: number =
            100 + 50 + 10 + 100 + 10 + 90 + 10 + 60 + 10 + 10 + 48 + 35;
        const tableHeight = fixTable(height);
        const state = reactive({
            selectedRowKeys: [],
            selectedRows: [],
        });
        const searchPattern = ref<string>('plan');
        const functionType = ref<string>('');

        const currentChecnkingPlan = computed(() => {
            if (
                checkingRound.value &&
                batchName.value &&
                submissionDate.value
            ) {
                return `${checkingRound.value} / ${batchName.value}: ${submissionDate.value}`;
            } else {
                return '';
            }
        });

        const onSelectChange = (selectedRowKeys: [], selectedRows: []) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        watch(tableData, (_: any) => {
            state.selectedRowKeys = [];
            state.selectedRows = [];
        });

        //* 查询 重置
        const getDataBySearch = () => {
            const params = {
                bus: bus.value,
                programCode: programCode.value,
                programName: programName.value,
                current: pagination.currentPage - 1,
                size: pagination.pageSize,
                sipoReview: sipoReview.value,
                waitingSipoCommunication: waitingSipoCommunication.value,
            };
            SIPOApi.searchData(params).then((res) => {
                tableData.value = res.data;
                pagination.total = parseInt(res.totalNum);
            });
        };

        const search = () => {
            searchPattern.value = 'search';
            pagination.currentPage = 1;
            getDataBySearch();
        };

        const reset = () => {
            programCode.value = '';
            programName.value = '';
            sipoReview.value = 3;
            waitingSipoCommunication.value = 3;
        };

        // //* 获取 checking round 数据
        const getCheckingRoundList = () => {
            const params = {
                bus: bus.value,
                assignee: '',
            };
            return SIPOApi.getCheckingRoundList({ params }).then((res) => {
                checkingRoundList.value = res.data;
                if (res.data.length !== 0) {
                    checkingRound.value =
                        checkingRoundList.value[0].checkingRound;
                    currentPlanDetailIds.value =
                        checkingRoundList.value[0].planDetailIds;
                    //TODO 没有数据怎么办？
                }
            });
        };

        //* 获取 batch 数据
        const getBatchList = () => {
            if (currentPlanDetailIds.value === '') return;
            const params = {
                planDetailIds: currentPlanDetailIds.value,
            };
            SIPOApi.getBatchList(params).then((res) => {
                batchData.value = res.data;
            });
        };

        //* 根据batch来获取数据 plan
        const getDataByBatch = () => {
            const params = {
                bus: bus.value,
                current: pagination.currentPage - 1,
                // roundId: currentRoundId.value,
                planDetailIds: currentPlanDetailIds.value,
                size: pagination.pageSize,
                submissionDate: submissionDate.value,
            };
            SIPOApi.getDataByBatch(params).then((res) => {
                tableData.value = res.data;
                pagination.total = parseInt(res.totalNum);
            });
        };

        //* 根据batch来获取数据 additional
        const getDataByBatchAdditional = () => {
            const params = {
                bus: bus.value,
                current: pagination.currentPage - 1,
                roundId: currentRoundId.value,
                size: pagination.pageSize,
                batchName: batchName.value,
            };
            SIPOApi.getDataByBatchAdditional(params).then((res) => {
                tableData.value = res.data;
                pagination.total = parseInt(res.totalNum);
            });
        };

        //* 开始获取数据逻辑
        const notAllowSearch = ref(true);
        onMounted(() => {
            getUser()
                .then((res) => {
                    assignee.value = res.usrnm;
                    buList.value = res.buList.filter((ele: any) => {
                        return ele.parentId === null;
                    });
                })
                .then(() => {
                    return getCheckingRoundList();
                })
                .then(() => {
                    getBatchList();
                })
                .then((_) => {
                    notAllowSearch.value = false;
                });
        });

        //? Checking Round modal
        const showCheckingRoundModal = () => {
            searchPattern.value = 'plan';
            checkingRoundVisible.value = true;
        };
        const closeModal = () => {
            checkingRoundVisible.value = false;
        };
        // 选择其他的 checking round
        const selectCheckingRound = (record: any) => {
            tableData.value = [];
            checkingRound.value = record.checkingRound;
            currentPlanDetailIds.value = record.planDetailIds;
            getBatchList();
            reset();
        };

        const emptyProgramCheck = () => {
            if (tableData.value.length === 0) {
                message.error('Please select program');
                return true;
            }
            return false;
        };

        //? SIPO Checking Modal
        const exportList = () => {
            if (emptyProgramCheck()) return;
            if (
                searchPattern.value !== 'plan' &&
                searchPattern.value !== 'additional'
            ) {
                message.warning('Please select checking round');
                return;
            }
            const params = {
                url: '/claimapi/checking/sipo/main/plan/export',
                method: 'get',
                params: {
                    checkingRoundId: currentRoundId.value,
                    checkingRoundName: checkingRound.value,
                },
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
        };

        const getPlanConfirmList = () => {
            const params = {
                bus: bus.value,
                current: pagination.currentPage - 1,
                // roundId: currentRoundId.value,
                planDetailIds: currentPlanDetailIds.value,
                size: pagination.pageSize,
                submissionDate: submissionDate.value,
            };
            return SIPOApi.getPlanConfirmList(params).then((res) => {
                if (!res) {
                    message.error(
                        'No claims are need to SIPO confirm for current programs'
                    );
                    return false;
                } else {
                    const temp = res;
                    temp && temp.push({});
                    sipoModalDataSource.value = temp;
                    return true;
                }
            });
        };

        const getAdditionalConfirmList = () => {
            const params = {
                bus: bus.value,
                current: pagination.currentPage - 1,
                roundId: currentRoundId.value,
                size: pagination.pageSize,
                batchName: batchName.value,
            };
            return SIPOApi.getAdditionalConfirmList(params).then((res) => {
                if (!res) {
                    message.error(
                        'No claims are need to SIPO confirm for current programs'
                    );
                    return false;
                } else {
                    const temp = res;
                    temp && temp.push({});
                    sipoModalDataSource.value = temp;
                    return true;
                }
            });
        };

        const getSearchConfirmList = () => {
            const params = {
                bus: bus.value,
                programCode: programCode.value,
                programName: programName.value,
                current: pagination.currentPage - 1,
                size: pagination.pageSize,
                sipoReview: sipoReview.value,
                waitingSipoCommunication: waitingSipoCommunication.value,
            };
            return SIPOApi.getSearchConfirmList(params).then((res) => {
                if (!res) {
                    message.error(
                        'No claims are need to SIPO confirm for current programs'
                    );
                    return false;
                } else {
                    const temp = res;
                    temp && temp.push({});
                    sipoModalDataSource.value = temp;
                    return true;
                }
            });
        };

        const sipoConfirm = () => {
            if (emptyProgramCheck()) return;
            const confirmPormise = new Promise((resolve, reject) => {
                if (state.selectedRowKeys.length === 0) {
                    if (searchPattern.value === 'plan') {
                        getPlanConfirmList().then((res: any) => {
                            resolve(res);
                        });
                    }
                    if (searchPattern.value === 'additional') {
                        getAdditionalConfirmList().then((res: any) => {
                            resolve(res);
                        });
                    }
                    if (searchPattern.value === 'search') {
                        getSearchConfirmList().then((res: any) => {
                            resolve(res);
                        });
                    }
                } else {
                    const selectedPrograms: any = [];
                    state.selectedRows.forEach((row: any) => {
                        if (row.sipoReview !== 0) {
                            selectedPrograms.push(row);
                        }
                    });
                    if (!selectedPrograms.length) {
                        message.error(
                            'No claims are need to SIPO confirm for current programs'
                        );
                    } else {
                        SIPOApi.checkSignConfirmList(selectedPrograms).then(
                            (res) => {
                                const temp = res;
                                temp && temp.push({});
                                sipoModalDataSource.value = temp;
                                resolve(true);
                            }
                        );
                    }
                }
            });
            confirmPormise.then((res) => {
                if (res) {
                    functionType.value = 'confirm';
                    sipoCheckVisible.value = true;
                    sipoCheckTitle.value =
                        "Do you confirm <span class='title-word'>SIPO Confirm</span> below programs: ";
                }
            });
        };

        //? 7777 set dealer communication
        const getPlanCommunicationList = () => {
            const params = {
                bus: bus.value,
                current: pagination.currentPage - 1,
                // roundId: currentRoundId.value,
                planDetailIds: currentPlanDetailIds.value,
                size: pagination.pageSize,
                submissionDate: submissionDate.value,
            };
            return SIPOApi.getPlanCommunicationList(params).then((res) => {
                if (!res) {
                    message.error(
                        'No claims are need to communicate in current programs'
                    );
                    return false;
                } else {
                    const temp = res;
                    temp && temp.push({});
                    sipoModalDataSource.value = temp;
                    return true;
                }
            });
        };

        const getAdditionalCommunicationList = () => {
            const params = {
                bus: bus.value,
                current: pagination.currentPage - 1,
                roundId: currentRoundId.value,
                size: pagination.pageSize,
                batchName: batchName.value,
            };
            return SIPOApi.getAdditionalCommunicationList(params).then(
                (res) => {
                    if (!res) {
                        message.error(
                            'No claims are need to communicate in current programs'
                        );
                        return false;
                    } else {
                        const temp = res;
                        temp && temp.push({});
                        sipoModalDataSource.value = temp;
                        return true;
                    }
                }
            );
        };

        const getSearchCommunicationList = () => {
            const params = {
                bus: bus.value,
                programCode: programCode.value,
                programName: programName.value,
                current: pagination.currentPage - 1,
                size: pagination.pageSize,
                sipoReview: sipoReview.value,
                waitingSipoCommunication: waitingSipoCommunication.value,
            };
            return SIPOApi.getSearchCommunicationList(params).then((res) => {
                if (!res) {
                    message.error(
                        'No claims are need to communicate in current programs'
                    );
                    return false;
                } else {
                    const temp = res;
                    temp && temp.push({});
                    sipoModalDataSource.value = temp;
                    return true;
                }
            });
        };

        const setCommunication = () => {
            if (emptyProgramCheck()) return;
            const communicationPormise = new Promise((resolve, reject) => {
                if (state.selectedRowKeys.length === 0) {
                    if (searchPattern.value === 'plan') {
                        getPlanCommunicationList().then((res: any) => {
                            resolve(res);
                        });
                    }
                    if (searchPattern.value === 'additional') {
                        getAdditionalCommunicationList().then((res: any) => {
                            resolve(res);
                        });
                    }
                    if (searchPattern.value === 'search') {
                        getSearchCommunicationList().then((res: any) => {
                            resolve(res);
                        });
                    }
                } else {
                    const selectedPrograms: any = [];
                    state.selectedRows.forEach((row: any) => {
                        if (row.waitingSipoCommunication !== 0) {
                            selectedPrograms.push(row);
                        }
                    });
                    if (!selectedPrograms.length) {
                        message.error(
                            'No claims are need to communicate in current programs'
                        );
                    } else {
                        SIPOApi.checkSignCommunicationList(
                            selectedPrograms
                        ).then((res) => {
                            const temp = res;
                            temp && temp.push({});
                            sipoModalDataSource.value = temp;
                            resolve(true);
                        });
                    }
                }
            });
            communicationPormise.then((res) => {
                if (res) {
                    functionType.value = 'communication';
                    sipoCheckVisible.value = true;
                    sipoCheckTitle.value =
                        "Do you confirm <span class='title-word'>Set Dealer Communication</span> below programs: ";
                }
            });
        };

        const closeSIPOModal = () => {
            sipoCheckVisible.value = false;
            if (searchPattern.value === 'plan') {
                getDataByBatch();
            }
            if (searchPattern.value === 'additional') {
                getDataByBatchAdditional();
            }
            if (searchPattern.value === 'search') {
                getDataBySearch();
            }
        };

        //? 分页操作
        const pageChange = (page: number) => {
            pagination.currentPage = page;
            if (searchPattern.value === 'plan') {
                getDataByBatch();
            }
            if (searchPattern.value === 'additional') {
                getDataByBatchAdditional();
            }
            if (searchPattern.value === 'search') {
                getDataBySearch();
            }
        };
        const sizeChange = (page: number, pageSize: number) => {
            pagination.pageSize = pageSize;
            pagination.currentPage = 1;
            if (searchPattern.value === 'plan') {
                getDataByBatch();
            }
            if (searchPattern.value === 'additional') {
                getDataByBatchAdditional();
            }
            if (searchPattern.value === 'search') {
                getDataBySearch();
            }
        };

        const jumpToDetails = (record: any) => {
            router.push({
                name: 'SIPOCheckingDetail',
                params: {
                    programId: record.programId,
                    programCode: record.programCode,
                    programName: record.programNameEn,
                    programCategory: record.programCategoryNameEn,
                    programCategoryId: record.programCategoryId,
                    checkingPlan: currentChecnkingPlan.value,
                    buId: record.buId,
                    buName: record.buName,
                    offerTypePathKey: record.offerTypePathKey,
                    offerTypeNameEn: record.offerTypeNameEn,
                    batchName: batchName.value,
                    submissionDate: submissionDate.value,
                },
            });
        };

        // 点击plan后面的数字 或者 自动选择显示在最前面的batch的plan
        const selectBatchPlan = (item: any) => {
            reset();
            searchPattern.value = 'plan';
            batchName.value = item.batchName;
            currentRoundId.value = item.roundId;
            submissionDate.value = item.submissionDate;
            pagination.currentPage = 1;
            getDataByBatch();
        };

        const selectBatchAdditional = (item: any) => {
            reset();
            searchPattern.value = 'additional';
            currentRoundId.value = item.roundId;
            batchName.value = item.batchName;
            submissionDate.value = item.submissionDate;
            pagination.currentPage = 1;
            getDataByBatchAdditional();
        };

        return {
            functionType,
            sipoModalDataSource,
            checkingRound,
            checkingRoundList,
            checkingRoundVisible,
            programName,
            programCode,
            sipoReview,
            waitingSipoCommunication,
            sipoSelectItems,
            showCheckingRoundModal,
            closeModal,
            selectCheckingRound,
            batchData,
            ...toRefs(pagination),
            ...toRefs(state),
            onSelectChange,
            columns,
            tableData,
            tableHeight,
            jumpToDetails,
            pageChange,
            sizeChange,
            selectBatchPlan,
            selectBatchAdditional,
            sipoCheckVisible,
            sipoCheckTitle,
            exportList,
            sipoConfirm,
            setCommunication,
            closeSIPOModal,
            reset,
            search,
            notAllowSearch,
        };
    },
});
