import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95c59d18"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      visible: _ctx.modalVisible,
      title: "Checking Round List",
      footer: null,
      onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_table, {
          rowKey: "id",
          dataSource: _ctx.dataSource,
          columns: _ctx.columns,
          pagination: false
        }, {
          operation: _withCtx(({record}) => [
            _createElementVNode("span", {
              class: "checking-btn",
              onClick: ($event: any) => (_ctx.select(record))
            }, "Select", 8, _hoisted_1)
          ]),
          _: 1
        }, 8, ["dataSource", "columns"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}