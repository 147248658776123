
import {
    ref,
    defineComponent,
    h,
    toRefs,
    reactive,
    computed,
    watch,
} from 'vue';
import moment, { Moment } from 'moment';
import { message } from 'ant-design-vue';
import CheckingSIPOAPI from '@/API/checking/checkingSIPO';
export default defineComponent({
    props: {
        title: {
            type: String,
            required: true,
            default: '',
        },
        visible: {
            type: Boolean,
            required: true,
            default: false,
        },
        dataSource: {
            type: Array,
            required: true,
            default: () => [],
        },
        functionType: {
            type: String,
            required: true,
            default: '',
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        const deadline = ref<Moment>();
        const isDeadlineEmpty = ref<boolean>(false);
        const roundClosing = ref<string>('Y');
        const lastCommunication = ref<string>('Y');
        const commentValue = ref<string>('');
        const dealerCommunicationVisible = ref<boolean>(false);
        const { dataSource } = toRefs(props); //? 修改了父组件的值？
        const sumVal = reactive({
            approvedSum: 0,
            rejectedSum: 0,
            pendingSum: 0,
            totalSum: 0,
        });
        const totalCalculate = () => {
            sumVal.approvedSum = 0;
            sumVal.rejectedSum = 0;
            sumVal.pendingSum = 0;
            sumVal.totalSum = 0;
            dataSource.value.forEach((item: any) => {
                if (item.total !== undefined) {
                    sumVal.approvedSum += item.approvedQty;
                    sumVal.rejectedSum += item.rejectedQty;
                    sumVal.pendingSum += item.pendingQty;
                    sumVal.totalSum += item.total;
                }
            });
        };

        watch(dataSource, () => {
            totalCalculate();
        });

        // dataSource.value.push({});

        const dataLen = computed(() => {
            return dataSource.value.length - 1;
        });
        const renderNo = ({ index }: any) => {
            const obj = {
                children: index + 1,
                props: {} as any,
            };

            if (index === dataLen.value) {
                return h('span', { class: 'weight' }, `Total`);
            }

            return obj;
        };
        const renderApproved = ({ text, index }: any) => {
            const obj = {
                children: text,
                props: {} as any,
            };

            if (index === dataLen.value) {
                // obj.props.colSpan = 0;
                return h('span', { class: 'weight' }, sumVal.approvedSum);
            }

            return obj;
        };
        const renderRejected = ({ text, index }: any) => {
            const obj = {
                children: text,
                props: {} as any,
            };

            if (index === dataLen.value) {
                // obj.props.colSpan = 0;
                return h('span', { class: 'weight' }, sumVal.rejectedSum);
            }

            return obj;
        };
        const renderPending = ({ text, index }: any) => {
            const obj = {
                children: text,
                props: {} as any,
            };

            if (index === dataLen.value) {
                // obj.props.colSpan = 0;
                return h('span', { class: 'weight' }, sumVal.pendingSum);
            }

            return obj;
        };
        const renderTotal = ({ text, index }: any) => {
            const obj = {
                children: text,
                props: {
                    class: 'weight',
                } as any,
            };

            if (index === dataLen.value) {
                // obj.props.colSpan = 0;
                return h('span', { class: 'weight' }, sumVal.totalSum);
            }

            return obj;
        };
        const columns = [
            {
                title: 'No.',
                width: 80,
                // slots: {
                //     customRender: 'order',
                // },
                customRender: renderNo,
            },
            {
                title: 'Program Code',
                dataIndex: 'programCode',
            },
            {
                title: 'Program Category',
                dataIndex: 'programCategoryNameEn',
            },
            {
                title: 'Approved',
                dataIndex: 'approvedQty',
                width: 100,
                customRender: renderApproved,
            },
            {
                title: 'Rejected',
                dataIndex: 'rejectedQty',
                width: 100,

                customRender: renderRejected,
            },
            {
                title: 'Pending',
                dataIndex: 'pendingQty',
                width: 100,

                customRender: renderPending,
            },
            {
                title: 'Total',
                dataIndex: 'total',
                width: 100,
                customRender: renderTotal,
            },
        ];

        const close = () => {
            emit('close');
        };

        const resetDealerCommunication = () => {
            deadline.value = undefined;
            roundClosing.value = 'Y';
            lastCommunication.value = 'Y';
            commentValue.value = '';
        };

        const communicationClose = () => {
            dealerCommunicationVisible.value = false;
            resetDealerCommunication();
        };

        const selectDate = () => {
            isDeadlineEmpty.value = false;
        };

        const successInfo = () => {
            message.success({
                content:
                    'Request submitted successfully. Please query result in task center.',
                duration: 2,
            });
            communicationClose();
            close();
        };

        const successModal = () => {
            dealerCommunicationVisible.value = true;
        };

        const sipoConfirm = () => {
            const _dataSource = JSON.parse(JSON.stringify(props.dataSource));
            const params =
                _dataSource && _dataSource.slice(0, _dataSource.length - 1);
            CheckingSIPOAPI.sipoConfirm(params).then((res: any) => {
                // console.log(res, 'res');
                successInfo();
            });
        };

        const success = () => {
            if (props.functionType === 'confirm') {
                sipoConfirm();
            }
            if (props.functionType === 'communication') {
                successModal();
            }
        };

        const checkSignCommunicationConfirm = () => {
            const _dataSource = JSON.parse(JSON.stringify(props.dataSource));
            const params = {
                comment: commentValue.value,
                confirmDeadline:
                    deadline.value &&
                    (deadline.value as Moment).format('MM/DD/YYYY'),
                lastCommunication: lastCommunication.value,
                roundClosing: roundClosing.value,
                programs:
                    _dataSource && _dataSource.slice(0, _dataSource.length - 1), //?
                current: 0,
                size: 10,
            };
            CheckingSIPOAPI.checkSignCommunicationConfirm(params).then(
                (res) => {
                    //TODO communication 有数据后接口调试
                    console.log(res, 'checkSignCommunicationConfirm');
                    successInfo();
                }
            );
        };

        const communicationSuccess = () => {
            //? 校验: 1 必填校验
            if (!deadline.value) {
                isDeadlineEmpty.value = true;
                return;
            }
            //? 2 业务逻辑校验
            //? 沟通截止日期不得早于操作日期，否则报错：You cannot choose previous date
            const currentDate = new Date();
            if (
                Date.parse(deadline.value?.format('YYYY/MM/DD') as string) <
                Date.parse(currentDate.toDateString())
            ) {
                message.error({
                    content: 'You cannot choose previous date',
                    duration: 2,
                });
                return;
            }
            //? 若last communication是Y， round clsoing 不能为N，
            //? 否则报错：You cannot choose last communication if round is not closing
            if (lastCommunication.value === 'Y' && roundClosing.value === 'N') {
                message.error({
                    content:
                        'You cannot choose last communication if round is not closing',
                    duration: 2,
                });
                return;
            }
            checkSignCommunicationConfirm();
        };

        return {
            selectDate,
            isDeadlineEmpty,
            roundClosing,
            lastCommunication,
            deadline,
            commentValue,
            dealerCommunicationVisible,
            columns,
            close,
            renderNo,
            success,
            communicationSuccess,
            communicationClose,
        };
    },
});
