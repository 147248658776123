import { fetchGet, fetchPost } from '../index';

//! 获取checking round
const getCheckingRoundList = (params: any): Promise<any> => {
    // return fetchGet(
    //     //TODO 修改传参逻辑
    //     `/claimapi/checking/plan/detail/checking/round?bus=MB&assignee=RT`
    //     // `/claimapi/checking/plan/detail/checking/round?bus=${params.bus}&assignee=${params.assignee}`
    // );
    return fetchGet('/claimapi/checking/plan/detail/checking/round', params);
};

//! 获取batch
const getBatchList = (params: any): Promise<any> => {
    return fetchPost('/claimapi/checking/plan/detail/checking/batch', params);
};

//! 根据 batch 获取数据 plan
const getDataByBatch = (params: any): Promise<any> => {
    return fetchPost('/claimapi/checking/sipo/main/plan/page', params);
};
//! 根据 batch 获取数据 additional
const getDataByBatchAdditional = (params: any): Promise<any> => {
    return fetchPost('/claimapi/checking/sipo/main/additional/page', params);
};

//! search 查询接口
const searchData = (params: any): Promise<any> => {
    return fetchPost('/claimapi/checking/sipo/main/search/page', params);
};

//! 4.SIPO confirm 弹窗 by Plan
const getPlanConfirmList = (params: any): Promise<any> => {
    return fetchPost('/claimapi/checking/sipo/main/plan/confirm/list', params);
};

//! 4.SIPO confirm 弹窗 by additional
const getAdditionalConfirmList = (params: any): Promise<any> => {
    return fetchPost(
        '/claimapi/checking/sipo/main/additional/confirm/list',
        params
    );
};

//! 4.SIPO confirm 弹窗 by 查询按钮
const getSearchConfirmList = (params: any): Promise<any> => {
    return fetchPost(
        '/claimapi/checking/sipo/main/search/confirm/list',
        params
    );
};

//! 4.SIPO confirm 弹窗 by 对号
const checkSignConfirmList = (params: any): Promise<any> => {
    return fetchPost(
        '/claimapi/checking/sipo/main/check/sign/confirm/list',
        params
    );
};
//？ 7777 set dealer communication
//! 6.Set dealer communication 弹窗 by Plan
const getPlanCommunicationList = (params: any): Promise<any> => {
    return fetchPost(
        '/claimapi/checking/sipo/main/plan/set/dealer/communication/list',
        params
    );
};

//! 4.[OASIS-4975][Set dealer communication] 弹窗 By 查询按钮
const getAdditionalCommunicationList = (params: any): Promise<any> => {
    return fetchPost(
        '/claimapi/checking/sipo/main/additional/set/dealer/communication/list',
        params
    );
};

//! 4.SIPO confirm 弹窗 by 查询按钮
const getSearchCommunicationList = (params: any): Promise<any> => {
    return fetchPost(
        '/claimapi/checking/sipo/main/search/set/dealer/communication/list',
        params
    );
};

//! 4. [OASIS-4975][Set dealer communication] 弹窗 By 对号
const checkSignCommunicationList = (params: any): Promise<any> => {
    return fetchPost(
        '/claimapi/checking/sipo/main/check/sign/set/dealer/communication/list',
        params
    );
};

//! 7.Set dealer communication 弹窗 后confirm操作
const checkSignCommunicationConfirm = (params: any): Promise<any> => {
    return fetchPost(
        '/claimapi/checking/sipo/main/check/sign/set/dealer/communication/confirm',
        params
    );
};

//! [OASIS-4975][SIPO Confirm] 弹窗 后[confirm]操作
const sipoConfirm = (params: any): Promise<any> => {
    return fetchPost(
        '/claimapi/checking/sipo/main/sipoConfirm/confirm',
        params
    );
};

export default {
    getCheckingRoundList,
    getBatchList,
    searchData,
    getDataByBatch,
    getDataByBatchAdditional,
    getPlanConfirmList,
    getAdditionalConfirmList,
    getSearchConfirmList,
    checkSignConfirmList,
    getPlanCommunicationList,
    getAdditionalCommunicationList,
    getSearchCommunicationList,
    checkSignCommunicationList,
    checkSignCommunicationConfirm,
    sipoConfirm,
};
