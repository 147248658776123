
import {
    Ref,
    ref,
    defineComponent,
    reactive,
    toRefs,
    watch,
    computed,
} from 'vue';
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue';

export default defineComponent({
    name: 'batch',
    components: {
        LeftOutlined,
        RightOutlined,
    },
    props: {
        batchData: {
            type: Array,
            reuqired: true,
            default: () => [],
        },
        type: {
            type: String,
            required: false,
            default: '',
        },
    },
    emits: ['select-batch-plan', 'select-batch-additional'],
    setup(props, { emit }) {
        const BATCH_LEN = 4;
        const { batchData } = toRefs(props);
        let batchIndex: number[] = [];
        const showIndexList = ref<number[]>([]);
        const startIndex = ref<number>(0);
        const endIndex = ref<number>(4);

        //TODO 根据日期初始化 考虑细节 比如 start end 超出边界了怎么办   ---如果长度直接小于4呢
        // const currentDate = new Date();
        // for (let i = 0; i < props.batchData.length; i++) {
        //     const date = (props as any).batchData[i].batchDate;
        //     if (Date.parse(date) > Date.parse(currentDate.toString())) {
        //         startIndex = i;
        //         endIndex = startIndex + BATCH_LEN;
        //         showIndexList.value = batchIndex.slice(startIndex, endIndex);
        //         break;
        //     }
        // }
        const initShownBatchData = (dataList: any, prop: string) => {
            const currentDate = new Date();
            let start = 0;
            let end = 4;
            let indexList: number[] = [];
            let flag = false;
            for (let i = 0; i < dataList.length; i++) {
                const date = dataList[i][prop];
                if (
                    Date.parse(date) >= Date.parse(currentDate.toDateString())
                ) {
                    flag = true;
                    start = i;
                    // end = Math.min(start + BATCH_LEN, dataList.length);
                    end = start + Math.min(BATCH_LEN, dataList.length);
                    indexList = batchIndex.slice(start, end);
                    break;
                }
            }
            if (!flag) {
                start = dataList.length - 1;
                end = start + BATCH_LEN;
                indexList = batchIndex.slice(start, end);
            }
            if ((props as any).type !== 'noInit') {
                emit('select-batch-plan', dataList[start]);
            }
            showIndexList.value = indexList;
            startIndex.value = start;
            endIndex.value = end;
        };

        watch(batchData, (newVal) => {
            batchIndex = Array.from(batchData.value.keys());
            initShownBatchData(newVal, 'submissionDate');
        });

        const moveLeft = () => {
            //TODO 边界判断
            startIndex.value += 1;
            endIndex.value += 1;
            showIndexList.value = batchIndex.slice(
                startIndex.value,
                endIndex.value
            );
        };
        const moveRight = () => {
            startIndex.value -= 1;
            endIndex.value -= 1;
            showIndexList.value = batchIndex.slice(
                startIndex.value,
                endIndex.value
            );
        };
        const moveStyle = (index: number) => {
            return {
                display: showIndexList.value.includes(index) ? '' : 'none',
            };
        };

        const selectBatchPlan = (item: any) => {
            emit('select-batch-plan', item);
        };

        const selectBatchAdditional = (item: any) => {
            emit('select-batch-additional', item);
        };

        // const isNotLeftBoundary = ref<boolean>(true);
        // const isNotRightBoundary = ref<boolean>(true);
        // watch(
        //     startIndex,
        //     (newVal) => {
        //         if (newVal === 0) {
        //             isNotLeftBoundary.value = false;
        //         } else {
        //             isNotLeftBoundary.value = true;
        //         }
        //     },
        //     { immediate: true }
        // );
        // watch(
        //     endIndex,
        //     (newVal) => {
        //         console.log('endIndex', newVal);
        //         if (
        //             batchData.value.length !== 0 &&
        //             newVal >= batchData.value.length
        //         ) {
        //             isNotRightBoundary.value = false;
        //         } else {
        //             isNotRightBoundary.value = true;
        //         }
        //     },
        //     { immediate: true }
        // );
        //* 左边没有顶头
        const isNotLeftBoundary = computed(() => {
            if (startIndex.value === 0) {
                return false;
            } else {
                return true;
            }
        });
        //* 右边没有顶尾
        const isNotRightBoundary = computed(() => {
            if (
                batchData.value.length !== 0 &&
                endIndex.value >= batchData.value.length
            ) {
                return false;
            } else {
                return true;
            }
        });

        //TODO additional 选择事件 修改 isSearch

        return {
            isNotLeftBoundary,
            isNotRightBoundary,
            moveLeft,
            moveRight,
            moveStyle,
            selectBatchPlan,
            selectBatchAdditional,
        };
    },
});
